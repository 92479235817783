/**
 * Draggable Items
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

import Sortable from 'sortablejs';

const list = document.querySelector('.admin-items-sortable');
const handle = '<span class="drag-handle"></span>';

const updateWeights = (data = []) => {
	if (data.length > 0) {
		try {
			fetch(`${location.pathname}/gewichtung`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				cache: 'no-cache',
				credentials: 'include',
				body: JSON.stringify(data),
			});
		} catch (error) {
			console.error(error);
		}
	}
};

const handleDragEnd = () => {
	if (!list) {
		return;
	}

	const inputs = list.querySelectorAll('input[name="id"]');
	const weights = [...inputs.keys()].reverse();
	const data = [];
	for (const [index, {value}] of inputs.entries()) {
		data.push({
			id: Number.parseInt(value, 10),
			weight: weights[index] + 1,
		});
	}

	updateWeights(data);
};

if (list && list.querySelectorAll('li').length > 1) {
	for (const item of list.querySelectorAll('li')) {
		item.insertAdjacentHTML('afterbegin', handle);
	}

	Sortable.create(list, {
		animation: 150,
		easing: 'cubic-bezier(1, 0, 0, 1)',
		handle: '.drag-handle',
		onEnd: handleDragEnd,
	});
}
