/**
 * Map
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

/* eslint-disable n/no-unsupported-features/node-builtins */
/* global google */

const map = document.querySelector('#map');
const apiKey = 'AIzaSyALELautMFcC-DvRsDMEeX7ALUUGF4l1XE';
// Const aspectRatio = 0.35;

const loadApi = (callback) => {
	const callbackFunction = 'googleMapsInit';
	const source = `https://maps.googleapis.com/maps/api/js?callback=${callbackFunction}&key=${apiKey}&libraries=marker&loading=async`;

	// Register global init callback
	window[callbackFunction] = callback;

	// Append script
	const script = document.createElement('script');
	script.async = true;
	script.src = source;
	document.body.append(script);
};

const setDimensions = () => {
	if (map) {
		const height = 300; // Math.round(map.offsetWidth * aspectRatio);
		map.style.width = '100%';
		map.style.height = `${height}px`;
	}
};

const initializeMap = () => {
	if (map) {
		map.classList.remove('not-initialized');
		map.innerHTML = '';
		loadApi(() => {
			if (!google) {
				return;
			}

			// Initialize the map
			const googleMap = new google.maps.Map(map, {
				zoom: 16,
				center: new google.maps.LatLng(47.940_57, 8.551_15),
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				scrollwheel: false,
				streetViewControl: false,
				disableDefaultUI: true,
				mapId: 'DEMO_MAP_ID',
			});

			/* Set map styles */
			const mapStyles = new google.maps.StyledMapType(
				[
					{
						featureType: 'road',
						elementType: 'geometry',
						stylers: [
							{
								visibility: 'simplified',
							},
						],
					},
					{
						featureType: 'road.arterial',
						elementType: 'geometry',
						stylers: [
							{
								color: '#bdbdbb',
							},
							{
								visibility: 'simplified',
							},
						],
					},
					{
						featureType: 'water',
						elementType: 'geometry',
						stylers: [
							{
								color: '#17a2b8',
							},
							{
								visibility: 'simplified',
							},
						],
					},
					{
						featureType: 'poi',
						stylers: [
							{
								visibility: 'off',
							},
						],
					},
				],
				{
					name: 'Ketterer Karte',
				},
			);
			googleMap.mapTypes.set('ketterer', mapStyles);
			googleMap.setMapTypeId('ketterer');

			// Parse hCard and position appropriate marker on the map
			const geocoder = new google.maps.Geocoder();
			const addresses = document.querySelectorAll('.adr');
			for (const card of addresses) {
				const streetAddress =
					card.querySelector('.street-address')?.textContent;
				const postalCode =
					card.querySelector('.postal-code')?.textContent;
				const locality = card.querySelector('.locality')?.textContent;
				if (streetAddress && postalCode && locality) {
					geocoder.geocode(
						{
							address: `${streetAddress}, ${postalCode} ${locality}`,
						},
						(results, status) => {
							if (status === google.maps.GeocoderStatus.OK) {
								const marker =
									new google.maps.marker.AdvancedMarkerElement(
										{
											position:
												results[0].geometry.location,
										},
									);
								marker.setMap(googleMap);
							}
						},
					);
				}
			}

			// Resize map on window resize
			window.addEventListener('resize', () => {
				// Apply new height based on existing aspect ratio
				setDimensions();

				// Re-center map
				const center =
					googleMap.getCenter() ?? new google.maps.LatLng(0, 0);
				google.maps.event.trigger(googleMap, 'resize');
				googleMap.setCenter(center);
			});
		});
	}
};

if (map) {
	// Apply dimensions
	setDimensions();

	if (localStorage.getItem('allow-map') === 'true') {
		initializeMap();
	} else {
		map.classList.add('not-initialized');
		map.innerHTML = `
			<div class="map-toggle">
				<button type="button" class="button button-secondary">Karte anzeigen</button>
			</div>
			<div class="map-disclaimer">
				<p>Wir verwenden die Google Maps API, um geografische Informationen visuell darzustellen. Mit Klick auf den Button stimmen Sie zu, dass von Google Daten über die Nutzung der Kartenfunktionen erhoben, verarbeitet und genutzt werden.</p>
				<p>Nähere Informationen über die Daten&shy;verarbeitung durch Google können Sie den <a href="https://www.google.com/intl/de_de/help/terms_maps.html">Google Maps Nutzerbedingungen</a> und der <a href="https://policies.google.com/privacy">Google Datenschutzerklärung</a> entnehmen; dort können Sie im Datenschutzcenter auch Ihre persönlichen Datenschutz-Einstellungen verändern.</p>
			</div>
		`;

		const toggle = document.querySelector('.map-toggle button');
		if (toggle) {
			localStorage.setItem('allow-map', 'true');
			toggle.addEventListener('click', initializeMap);
		}
	}
}
