/**
 * Lava Animation
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */
import $ from 'jquery';

/** Timeout due to Webfonts. */
const navigationTimeout = 250;

const sizeOffset = 24;

window.setTimeout(() => {
	const navigation = $('nav ul');
	if (navigation.length > 0) {
		$(() => {
			navigation
				.on(
					'mouseenter mouseleave focusin focusout',
					'a',
					{
						lava: $('<div class="lava">').appendTo('nav'),
					},
					function (event) {
						const elm = /(mouseleave|focusout)/.test(event.type)
							? $('nav .active')[0]
							: this;
						$('.lava').toggleClass(
							'active',
							$(elm).hasClass('active'),
						);
						event.data.lava.animate(
							{
								width: elm ? elm.offsetWidth - sizeOffset : 0,
								left: elm ? elm.offsetLeft + sizeOffset / 2 : 0,
							},
							{
								duration: 200,
								easing: 'linear',
								queue: false,
							},
						);
					},
				)
				.find('a:first')
				.trigger('mouseleave');
		});
	}
}, navigationTimeout);
