/**
 * Floating Labels
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

const inputs = document.querySelectorAll('.form-control input');
const dropdowns = document.querySelectorAll('.dropdown');

const handleInput = (input) => {
	const parent = input.parentElement;
	const isEmpty = input.value === '';
	parent.classList.toggle('form-control-empty', isEmpty);
	parent.classList.toggle('form-control-filled', !isEmpty);
};

const handleDropdown = (select, output) => {
	const option = select.options[select.selectedIndex];
	const parent = select.parentElement.parentElement;
	const isEmpty = option.value === '';
	output.textContent = isEmpty ? option.dataset.text : option.label;
	parent.classList.toggle('form-control-empty', isEmpty);
	parent.classList.toggle('form-control-filled', !isEmpty);
};

for (const input of inputs) {
	input.addEventListener('input', () => {
		handleInput(input);
	});
	handleInput(input);
}

for (const dropdown of dropdowns) {
	const select = dropdown.querySelector('select');
	const output = dropdown.querySelector('output');
	if (select && output) {
		select.addEventListener('change', () => {
			handleDropdown(select, output);
		});
		handleDropdown(select, output);
	}
}
