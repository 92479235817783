/**
 * Footer Disclosures
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

import $ from 'jquery';

const updateDisclosure = (matches) => {
	if (matches) {
		$('.footer-disclosure').each(function () {
			const $disclosure = $(this);
			const $button = $disclosure.find('summary');
			$disclosure.removeAttr('open');
			$button.off('click.disclosure');
		});
	} else {
		$('.footer-disclosure').each(function () {
			const $disclosure = $(this);
			const $button = $disclosure.find('summary');
			$disclosure.attr('open', 'open');
			$button.on('click.disclosure', (event) => {
				event.preventDefault();
			});
		});
	}
};

const mobileQuery = window.matchMedia('(max-width: 639px)');
mobileQuery.addListener((event) => updateDisclosure(event.matches));
updateDisclosure(mobileQuery.matches);
