/**
 * Form Element Cloner
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

import $ from 'jquery';

const toggleRemovalButton = () => {
	$('#remove-clone').prop('disabled', $('.group li').length === 1);
};

$('#clone').on('click', () => {
	const $fields = $('.group li').last().clone();
	const numericalId = $('.group li').length + 1;
	$fields.find('input, textarea').each(function () {
		const $label = $(this).prev('label');
		const $input = $(this);

		const id = $input.attr('id').replace(/\d+$/, numericalId);

		$label.attr('for', id);
		$input.attr('id', id);
		$input.val('');
	});

	const $toolbar = $fields.find('markdown-toolbar');
	const id = $toolbar.attr('for').replace(/\d+$/, numericalId);
	$toolbar.attr('for', id);

	$fields.appendTo('.group ul');

	toggleRemovalButton();
});

$('#remove-clone').on('click', () => {
	$('.group li').last().remove();

	toggleRemovalButton();
});
