/**
 * Navigation
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

const skiplink = document.querySelector('#skiplink');
const content = document.querySelector('main');
const activeClass = 'show-navigation';

const toggleNavigation = (event) => {
	event.preventDefault();
	document.documentElement.classList.toggle(activeClass);
};

if (skiplink) {
	skiplink.addEventListener('click', toggleNavigation);
}

if (content) {
	content.addEventListener('click', (event) => {
		if (document.documentElement.classList.contains(activeClass)) {
			toggleNavigation(event);
		}
	});
}
