/**
 * Admin Forms
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

const slugInput = document.querySelector('#slug');
const slugOutput = document.querySelector('#slug-output');
const videoInput = document.querySelector('#video');

/** Create and set URL slug. */
const createSlug = () => {
	const title = document.querySelector('#title').value;
	if (title !== '') {
		const slug = title
			.trim()
			.toLowerCase()
			.replaceAll('ä', 'ae')
			.replaceAll('ö', 'oe')
			.replaceAll('ü', 'ue')
			.replaceAll('ß', 'ss')
			.replaceAll('é', 'e')
			.replaceAll('è', 'e')
			.replaceAll('á', 'a')
			.replaceAll('à', 'a')
			.replaceAll('ñ', 'n')
			.replaceAll('€', 'euro')
			.replaceAll(/\s\((?:[mwd]\/?){1,3}\)$/g, '') // Remove job gender from slug
			.replaceAll(/\W/g, '-') // Replace all other characters with dash
			.replaceAll('_', '-')
			.replaceAll('--', '-') // Replace double dash
			.replaceAll(/-$/g, '') // Replace final dash
			.slice(0, 99); // Keep the URL short (100 chars only)
		slugInput.value = slug;
		if (slugOutput) {
			const slugPrefix = slugOutput.dataset.prefix;
			slugOutput.textContent = `${slugPrefix}${slug}`;
		}
	}
};

/** Parse YouTube or Vimeo embed code and set normal video URL. */
const parseEmbedCode = () => {
	const {value} = videoInput;
	if (value.indexOf('<') || value.indexOf('>')) {
		// Parse video ID from value
		const vimeo = value.match(/vimeo\.com\/video\/(\d+)/);
		const youtube = value.match(
			/(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/,
		);

		if (vimeo !== null) {
			videoInput.value = `https://vimeo.com/${vimeo[1]}`;
		}

		if (youtube !== null) {
			videoInput.value = `https://www.youtube.com/watch?v=${youtube[1]}`;
		}
	}
};

if (slugInput) {
	document.querySelector('#title').addEventListener('keyup', createSlug);
	const categoryInput = document.querySelector('input[name=category]');
	if (categoryInput) {
		categoryInput.addEventListener('input', createSlug);
	}
}

if (videoInput) {
	videoInput.addEventListener('input', parseEmbedCode);
}

const confirmDeletion = (event) => {
	// eslint-disable-next-line no-alert
	const confirmation = window.confirm('Sind Sie sicher?');
	if (!confirmation) {
		event.preventDefault();
	}
};

const deleteButtons = document.querySelectorAll(
	'.button-delete , .link-delete',
);
for (const button of deleteButtons) {
	button.addEventListener('click', confirmDeletion);
}

const deleteForms = document.querySelectorAll('.form-delete');
for (const form of deleteForms) {
	form.addEventListener('submit', confirmDeletion);
}
